<template>
  <div class="mb-0"> <!--v-if="currentEntry">--> 
    <h4 class="subtitle-form overload-subtitle mb-2" >Surface travaillée</h4>
    <div>
      <div v-if="!this.currentEntry.surface" class="text-activity-notes enpty-attr text-left">
               La surface travaillée n'est pas renseignée
      </div>
      <div v-else class="text-infos bg-white" >
        <span> {{ this.currentEntry.surface }} </span> <span> ha</span>
      </div>
    </div> 
    <hr/>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  name: "worked-surface",
  mixins: [DetailUpdateMixin],
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
/**
 * Overload subtitle-
 */
.overload-subtitle {
  margin: 0px 0px 0px 0px;
}
/**
 * Color text for empty list.
 */
.enpty-attr {
  text-align: center;
  color: $danger-color;
}

/**
 * Text params for empty attribut.
 */
.empty-attr {
  color: $danger-color;
}
</style>
